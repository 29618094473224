<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="modal-title">
    <h3>Broadcast analytics</h3>
  </div>
  <div style="height: 36px; margin-top: 8px; margin-left: 1px">
    <span class="tab-nav-link" (click)="getOverviewData()" [ngClass]="{'tab-nav-active': tab === 'overview' }">
      Overview
    </span>
    <span class="tab-nav-link" (click)="getClicksByUrlData()" [ngClass]="{'tab-nav-active': tab === 'clicksByUrl' }">
      Clicks by URL
    </span>
  </div>
</ion-header>

<ion-content>
  <div *ngIf="tab === 'overview'">
    <ion-grid>
      <app-survey-analytics *ngIf="hasSurvey" [surveyId]="surveyId" [outboundId]="outbound.id"></app-survey-analytics>
      <ion-row>
        <ion-col size="12" *ngIf="hasSurvey">
          <h3 class="column-header">Clicks & Opens</h3>
        </ion-col>
        <ion-col size-md="3" size="12">
          <ion-card class="outline-card report-card">
            <span class="report-stat">{{totalOpens}}</span>
            <div class="report-title">Opens</div>
          </ion-card>
        </ion-col>
        <ion-col size-md="3" size="12">
          <ion-card class="outline-card report-card">
            <span class="report-stat">{{uniqueOpens}}</span>
            <div class="report-title">Unique opens</div>
          </ion-card>
        </ion-col>
        <ion-col size-md="3" size="12">
          <ion-card class="outline-card report-card">
            <span class="report-stat">{{totalClicks}}</span>
            <div class="report-title">Clicks</div>
          </ion-card>
        </ion-col>
        <ion-col size-md="3" size="12">
          <ion-card class="outline-card report-card">
            <span class="report-stat">{{uniqueClicks}}</span>
            <div class="report-title">Unique clicks</div>
          </ion-card>
        </ion-col>
        <ion-col size="12">
          <ion-card class="outline-card">
            <ngx-datatable
              class="material fullscreen"
              [columnMode]="ColumnMode.flex"
              [limit]="100"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="50"
              [scrollbarH]="true"
              [rows]="data"
              [loadingIndicator]="isLoading"
            >
              <ngx-datatable-column name="Name" prop="user" [flexGrow]="2" [sortable]="true">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  {{getUserName(value)}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="isEmail" name="Email" prop="email" [flexGrow]="3" [sortable]="true">
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="isEmail" name="Opens" prop="opens" [flexGrow]="1" [sortable]="true">
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="isSlack" name="Channel" prop="channelName" [flexGrow]="3" [sortable]="true">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  {{getChannelName(value)}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Clicks" prop="clicks" [flexGrow]="1" [sortable]="true"></ngx-datatable-column>
            </ngx-datatable>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <div *ngIf="tab === 'clicksByUrl'" style="padding: 15px">
    <div *ngFor="let url of clicksByUrlData">
      <h3 style="font-size: 1rem; line-break: anywhere">{{url.url}}</h3>
      <ion-card class="outline-card" style="margin: 0">
        <ngx-datatable
          class="material fullscreen"
          [columnMode]="ColumnMode.flex"
          [limit]="100"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="50"
          [scrollbarH]="true"
          [rows]="url.items"
          [loadingIndicator]="isLoading"
        >
          <ngx-datatable-column name="Name" prop="user" [flexGrow]="2" [sortable]="true">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              {{getUserName(value)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="isEmail" name="Email" prop="email" [flexGrow]="3" [sortable]="true">
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="isSlack" name="Channel" prop="channelName" [flexGrow]="3" [sortable]="true">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              {{getChannelName(value)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Clicks" prop="clicks" [flexGrow]="1" [sortable]="true"></ngx-datatable-column>
        </ngx-datatable>
      </ion-card>
    </div>
    <div *ngIf="!clicksByUrlData || !clicksByUrlData.length">
      <div class="empty-state-container">
        <span class="placeholder-emoji">
          <ion-icon name="telescope-outline"></ion-icon>
        </span>
        <h1 class="placeholder-title">No clicks</h1>
        <p class="placeholder-subtitle">Any clicks on URLs will appear here</p>
      </div>
    </div>
  </div>
</ion-content>
