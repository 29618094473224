import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ColumnMode } from '@swimlane/ngx-datatable';
import _ from 'lodash';

import { OUTBOUND_TYPE } from 'src/app/constants';
import { Organizer, Outbound } from 'src/models';
import { ApiService } from 'src/services/api.service';

const TAB_OVERVIEW = 'overview';
const TAB_CLICKS_BY_URL = 'clicksByUrl';

@Component({
  selector: 'app-message-analytics',
  templateUrl: './message-analytics.page.html',
  styleUrls: ['./message-analytics.page.scss'],
})
export class MessageAnalyticsPage implements OnInit {
  tab = TAB_OVERVIEW;
  outbound: Outbound;
  community: Organizer;
  isEmail: boolean;
  isSlack: boolean;
  isLoading = true;
  data: any[] = [];
  clicksByUrlData: any[] = [];
  ColumnMode = ColumnMode;
  hasSurvey: boolean;
  surveyId?: string;

  totalOpens = 0;
  uniqueOpens = 0;
  totalClicks = 0;
  uniqueClicks = 0;
  surveyScore = 0;
  totalResponses = 0;
  surveyType?: string;
  surveyResponses: any[] = [];

  constructor(
    private modalCtrl: ModalController,
    private apiService: ApiService,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.isEmail = this.outbound.deliveryMethod === OUTBOUND_TYPE.EMAIL;
    this.isSlack = this.outbound.deliveryMethod === OUTBOUND_TYPE.SLACK;

    const survey = this.outbound.blocks?.find((b) => b.type === 'survey');

    this.surveyId = survey?.survey_id;
    this.hasSurvey = Boolean(survey);

    this.getOverviewData();

    const queryTab = this.route.snapshot.queryParamMap.get('tab');

    if (queryTab === TAB_CLICKS_BY_URL) {
      this.getClicksByUrlData();
    }
  }

  async getOverviewData() {
    this.tab = TAB_OVERVIEW;

    this.getData();
  }

  async getClicksByUrlData() {
    this.tab = TAB_CLICKS_BY_URL;

    this.getData(true);
  }

  async getData(clicksByUrl = false) {
    this.isLoading = true;

    const data = (await this.apiService.getPromise(
      `/outbounds/${this.outbound.id}/events${clicksByUrl ? '?clicksByUrl=1' : ''}`,
    )) as any;

    if (clicksByUrl) {
      const groupedData = data.reduce((acc, next) => {
        const url = next.url;

        if (!acc[url]) {
          acc[url] = [];
        }

        acc[url].push(next);

        return acc;
      }, {});

      this.clicksByUrlData = Object.entries(groupedData).map(([url, items]) => ({
        url,
        items,
      }));
    } else {
      this.data = data;
      this.getSummaryStats();
    }

    this.isLoading = false;
  }

  getSummaryStats() {
    this.totalClicks = 0;
    this.totalOpens = 0;
    this.uniqueClicks = 0;
    this.uniqueOpens = 0;
    this.data.map((entry) => {
      if (entry.clicks) {
        this.totalClicks += entry.clicks;
        this.uniqueClicks += 1;
      }
      if (entry.opens) {
        this.totalOpens += entry.opens;
        this.uniqueOpens += 1;
      }
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  getUserName(user: any) {
    return user?.name ?? 'Unknown';
  }

  getChannelName(channelName?: string) {
    return channelName || 'DM';
  }
}
